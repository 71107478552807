.query-form {
	
  max-width: 850px;
  width: 95%;
  margin: 10px auto;
  background: #fff;
  border: 2px solid #000;
  color: black;

  td {
    padding: 10px;
  }
  tr > td:nth-child(1) {
    width: 100px;
  }
  .MuiOutlinedInput-input,
  .MuiButton-outlined {
    background: white;
  }

  MuiInputBase-formControl {
  color: black;
  }

  .MuiRadio-colorSecondary {
    color: #262626 !important;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #262626 !important;
  }

  MuiSvgIcon-root:hover {
    color: #262626 !important;
  }

  .MuiButton-outlined {
      font-size: 12px;
      padding: 5px 20px;
      border: 2px solid #000 !important;
      border-radius: 3px !important;
      background-color: #fff !important;
      color: #000 !important;
      transition: all 250ms ease-in-out;
      cursor: pointer;
        &:hover {
          background-color: #000 !important;
          color:#fff !important;
          transition: background-color 1s linear !important;
          border: 2px solid #000 !important;
        }
        &:focus {
          outline: none;
        }
    }
}

.searchForm {
  background: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.searchTitle {
  font-size: 1.5em;
  text-align: center;
  color: white;
}

@media (max-width: 768px) {
  .searchTitle {
    font-size: 1em;
    text-align: center;
  }
}

@media (min-width: 640px) {
	.query-holder {
		padding: 32px;
	}
}


input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
