.search-bar {
	
	.MuiToolbar-gutters {
		padding-left: 16px;
		padding-right: 16px;
	}
	.postcode-field {

		-webkit-box-shadow:inset 0px 0px 0px 2px #fff;
    	-moz-box-shadow:inset 0px 0px 0px 2px #fff;
    	box-shadow:inset 0px 0px 0px 2px #fff;
		margin-top: 10px;
		background-color: white;
		border: 1px strong black;
		border-radius : 5px;
		color: black !important;

		border-radius: 5px;
		input {
			color: black;
		}
		::placeholder {
			color: black;
		}
		
		.Mui-focused * {
			border: 1px strong black;
			-webkit-box-shadow:inset 0px 0px 0px 1px #fff;
    		-moz-box-shadow:inset 0px 0px 0px 1px #fff;
    		box-shadow:inset 0px 0px 0px 1px #fff;
		}
	}

	.select-field {
		margin-top: 10px;
		width: 150px;
		.MuiFormLabel-root {
			opacity: 0.42;
		}
		label, input, .MuiSelect-root {
			color: black;
		}
		::placeholder {
			color: black;
			opacity: 0.42;
		}
		// .Mui-focused * {
		// 	border: none;
		// }
	}

	.MuiTypography-colorPrimary {
    color: grey;
}

}
