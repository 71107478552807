.search-results-paginator {
	width: 100%;
	border: 1px 0px;
	border-top: solid black;
	border-bottom: solid black;
	background-color: white;
	color: black;
	font-size: 1em;
	button {
		color: white;
	}
	.MuiInputBase-input {
		color: white;
	}
	.Mui-disabled, .Mui-disabled svg {
		color: black;
	}

	.MuiInputBase-input {
    	color: black !important;
	}

	.MuiFilledInput-underline:after {
		border-bottom: black !important;
	}

	.paginatorfont {
		font-size: 0.7em;
	
	@media (max-width: 640px) {
			font-size: 0.5em;
	}
	}

	.dropdownsection {
		padding: 10px;
	}


	.MuiButtonBase-root {
		color: grey !important;
	}

	.MuiButtonBase-root.Mui-disabled {
		color: white !important;
	}

	.MuiIconButton-root.Mui-disabled {
		color: white !important;
	}

}
