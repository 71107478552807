:root {
  --cta-colour: #000;
  --container-separator: 32px;
}

.property-title-container {
  background-color: white;
  color: black;
  border-left: var(--cta-colour) solid 3px;
  border-right: var(--cta-colour) solid 3px;
  margin: var(--container-separator) 0px;
  padding: 1px 16px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }

h1 {
  
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    // font-size: 3em;
}

.property-title-container ul {
  list-style: none;
}

.property-timeline-container {
  ul.timeline {
    border: white 1px solid;
  }
}

.counter-container {
  margin-top: var(--container-separator);
  background-color: white;
  border: 1px solid black;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

  .counter {
    text-align: center;

    .title {
      color: black;
      text-transform: capitalize;
      margin: 16px 0px;
    }

    .value {
      color: black;
      font-size: xx-large;
      font-weight: bold;
      margin: 16px 0px;
    }
  }
}

.price-container {

  margin-top: var(--container-separator);

	.text {
		border: 1px solid black;
    background-color:white;
		border-right: none;
		text-align: center;
		padding: var(--container-separator);
		color: black;
		text-align: left;
	}

  .price {
		border: 1px solid black;
    background-color: white;
		border-left: none;
		text-align: center;
		padding: var(--container-separator) 0px;
		line-height: var(--container-separator);
		vertical-align: middle;
		color: black;
		font-size: xx-large;		font-weight: bolder;
  }
}

.summary-container {
  color: black;

  .summary {
    margin-top: var(--container-separator);
    background-color: white;
    padding: 25px;
    border: 1px solid black;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }

  table {
    width: 100%;

  }

  h3 {
    text-transform: capitalize;

  }

  .title {
    color: black;
    font-size: small;
    font-weight: bold;
    padding: 4px 0px;
    text-transform: capitalize;
  }

  .value {
    color: grey;
    font-size: small;
    text-align: right;
    padding: 4px 0px;
  }
}

.footer-container {
  margin-top: calc(var(--container-separator)*2);
  color: white;
}

.print-pdf-container {
  margin-top: var(--container-separator);
  margin-bottom: var(--container-separator);
  padding-bottom: 150px;
}

hr.short {
  border: 2px solid var(--cta-colour);
  height: 0px;
  width: 50px;
  margin-left: 0px;
  margin-bottom: 25px;
}

.MuiTabs-root {
  color: black;
  .MuiTabs-indicator {
    background-color: grey;
  }
}

#root > div > div.MuiContainer-root.MuiContainer-maxWidthLg > div.MuiGrid-root.property-google-map-container > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-right > div > section > div.leaflet-control-layers-overlays > label:nth-child(1) > div > input,
#root > div > div.MuiContainer-root.MuiContainer-maxWidthLg > div.MuiGrid-root.property-google-map-container > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-right > div > section > div.leaflet-control-layers-overlays > label:nth-child(2) > div > input,
#root > div > div.MuiContainer-root.MuiContainer-maxWidthLg > div.MuiGrid-root.property-google-map-container > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-right > div > section > div.leaflet-control-layers-overlays > label:nth-child(3) > div > input,
#root > div > div.MuiContainer-root.MuiContainer-maxWidthLg > div.MuiGrid-root.property-google-map-container > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-right > div > section > div.leaflet-control-layers-overlays > label:nth-child(4) > div > input,
#root > div > div.MuiContainer-root.MuiContainer-maxWidthLg > div.MuiGrid-root.property-google-map-container > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-right > div > section > div.leaflet-control-layers-overlays > label:nth-child(5) > div > input,
#root > div > div.MuiContainer-root.MuiContainer-maxWidthLg > div.MuiGrid-root.property-google-map-container > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-right > div > section > div.leaflet-control-layers-overlays > label:nth-child(6) > div > input,
#root > div > div.MuiContainer-root.MuiContainer-maxWidthLg > div.MuiGrid-root.property-google-map-container > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-right > div > section > div.leaflet-control-layers-overlays > label:nth-child(7) > div > input,
#root > div > div.MuiContainer-root.MuiContainer-maxWidthLg > div.MuiGrid-root.property-google-map-container > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-right > div > section > div.leaflet-control-layers-overlays > label:nth-child(8) > div > input,
#root > div > div.MuiContainer-root.MuiContainer-maxWidthLg > div.MuiGrid-root.property-google-map-container > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-right > div > section > div.leaflet-control-layers-overlays > label:nth-child(9) > div > input,
#root > div > div.MuiContainer-root.MuiContainer-maxWidthLg > div.MuiGrid-root.property-google-map-container > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-right > div > section > div.leaflet-control-layers-overlays > label:nth-child(10) > div > input,
#root > div > div.MuiContainer-root.MuiContainer-maxWidthLg > div.MuiGrid-root.property-google-map-container > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-right > div > section > div.leaflet-control-layers-overlays > label:nth-child(11) > div > input
{
  visibility: visible !important;
}

.print {  
  #root > div > div {
    margin-top: 0px;

  }
  .google-map {
    height: 200px !important;
  }

  #root > div > header, header.MuiAppBar-root {
    display: none;
  }

  .MuiContainer-root.print-view {
    .MuiContainer-maxWidthLg {
      margin-top: 0px;
    }
    h1 {
      font-size: large;
    }
    .MuiGrid-root.property-google-map-container {
      overflow-y: hidden;
    }
  }
}