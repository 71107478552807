.rule:not(:hover) .rule--header {
	opacity: 50% !important;
}

.group {
    background: rgba(0, 0, 0, 1);
    border: 1px solid #fff;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
       }

 .group--conjunctions {
 	color: #fff;

 }

.rule {
    background-color: #000;
    border: 1px solid #fff;
    padding: 10px;
	  }

.rule--drag-handler {
    color: #fff;
  }

// Outlines

    select {
    border: none;
    outline: none;
    scroll-behavior: smooth;
  }

   * {
    outline: none;
  }


//Checkbox

	input[type="checkbox"] {
    visibility: hidden;
  }

   label {
   cursor: pointer;
  }

  	input[type="checkbox"] + label:before {
  	border: 2px solid #fff;
  	content: "\00a0";
  	display: inline-block;
  	height: 25px;
  	width: 25px;
   	margin: 0 0.25em 0 0;
   	padding-right: 0;
    vertical-align: top;
  }
  
  input[type="checkbox"]:checked + label:before {
    background: #91918a;
   	color: #fff;
   	content: "\2714";
    text-align: center;
    padding-top: 2px;
  }

  input[type="checkbox"]:checked + label:after {
    font-weight: bolder;
  }

  input[type="checkbox"]:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
  }

  //Radio
   	input[type="radio"] {
    display: none;
   }

  input[type="radio"] + label:before {
    content: "";
    /* create custom radiobutton appearance */
   	display: inline-block;
     width: 25px;
    height: 25px;
    padding: 6px;
  	margin-right: 3px;
    margin-left: 10px;
    /* background-color only for content */
   	background-clip: content-box;
   	border: 2px solid #fff;
   	background-color: #fff;
    border-radius: 50%;
  }

  /* appearance for checked radiobutton */
   input[type="radio"]:checked + label:before {
    background-color: #91918a;
   }

 // Buttons

 button {
		font-size: 14px;
		padding: 5px 20px;
		margin: 2px;
		border: 1px solid #fff;
		border-radius: 3px;
		background-color: #000000;
		color: #fff;
		transition: all 250ms ease-in-out;
		cursor: pointer;
		&:hover {
  		background-color: #91918a;
		}
	&:focus {
		// outline: none;
	}

// Slider
	.widget--widge {

	color: #fff;
	}

}

.query-builder {
  color: white;
  border-radius: 5px;
  background-color: black;
  margin: 5px;
  border: 1px white solid;
  padding: 5px;

  h2: {
    text-align: center;
  }
}