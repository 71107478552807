@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.graph {
	margin: auto;
  	width: 90%;
  	margin-top: 50px;
  	padding: 10px;
	border: 2px solid black;
	border-radius: 4px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

	-webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 3s; /* Firefox < 16 */
        -ms-animation: fadein 3s; /* Internet Explorer */
         -o-animation: fadein 3s; /* Opera < 12.1 */
            animation: fadein 3s;
}

.box-button {
      font-size: 14px;
      padding: 5px 20px;
      border: 2px solid #000 !important;
      border-radius: 3px !important;
      background-color: #fff !important;
      color: #000 !important;
      transition: all 500ms ease-in-out !important;
      cursor: pointer;
        &:hover {
          background-color: #000 !important;
          color:#fff !important;
          transition: all 500ms ease-in-out !important;
          border: 2px solid #000 !important;
        }
        &:focus {
          outline: none;
        }
    }