  .page-login {
        
    .textLogin {
      color: black;
      font-size: 14px;
    }

    .content .form .form-group input {
      width: 100%;
      display:flex;
      margin-top: 6px;
      height: 37px;
      padding: 0 10px;
      font-size: 16px;
      font-family: "Open Sans", sans-serif;
      background-color: #fff !important;
      border: 0;
      border-radius: 4px;
      transition: all 0.25s ease-in-out;
      border: 2px solid #000;
    }

    .content .form .form-group input:hover {
      background-color: #fff;
      box-shadow: 0.5px 0.5px 10px 0.3px #f3f3f3;
      }

    .form-group-top  {
      margin-top:50px;
    }

    .content .form .form-group {
      display: flex;
      align-items: flex-start;
      width: 100%;
    }

    .MuiButtonBase-root{
      position: absolute;
      top: 5%;
      right: 2%;
    }

    .MuiSvgIcon-root {
      color:#000;
    }

    .MuiButtonBase-root:hover {
      cursor: pointer;
    }
      
    .form-group-password {
      position: relative;
    }

    .header {
      font-size: 24px;
      color: #000;
      font-family: "Open Sans", sans-serif;
    }

    .btnfocus {
          font-size:18px; 
          border: 2px solid #000 !important;
          border-radius: 3px !important;
          background-color: #000 !important;
          color: #000 !important;
          cursor: pointer;
          transition: all 500ms ease-in-out;
            &:hover {
                background-color: #fff !important;
                border: 2px solid #000 !important;
                color:#fff !important;
                transition: all 500ms ease-in-out;
            }
        // for shine & gradient effects to work
        position: relative;
        overflow: hidden;
      // for background color shift
        background-image: (linear-gradient(270deg, #fff, #797979));
        background-size: 400% 400%;
        animation: TransitioningBackground 5s ease infinite;
      // to ease the button growth on hover
        transition: 0.8s;
      // psuedo-element shine animation left side
        &::before {
          content: '';
          display: block;
          position: absolute;
          background: rgba(255, 255, 255, 0.5);
          width: 60px;
          height: 100%;
          top: 0;
          filter: blur(30px);
          transform: translateX(-100px) skewX(-15deg);
  }

      // psuedo-element shine animation right side
        &::after {
        content: '';
        display: block;
        position: absolute;
        background: rgba(255, 255, 255, 0.2);
        width: 30px;
        height: 100%;
        top: 0;
        filter: blur(5px);
        transform: translateX(-100px) skewX(-15deg);
  }

      // grow button and change background gradient on hover
        &:hover {
          background-image: (linear-gradient(to left, #181818, #797979));
          transform: scale(1.2);
          cursor: pointer;

 }
    // psuedo-elements for right- and left-side shine animations
        &::before,
        &::after {
          transform: translateX(300px) skewX(-15deg);
          transition: 0.7s;
    }
}

    .btn {
      font-size: 18px;
      padding: 5px 20px;
      border: 2px solid #000;
      border-radius: 3px;
      background-color: #fff;
      color: #000;
      transition: all 500ms ease-in-out;
      cursor: pointer;
        &:hover {
          background-color: #000;
          border: 2px solid #000;
          color:#fff;
          transition: all 500ms ease-in-out;
        }
        &:focus {
          outline: none;
        }
    }

    .content {

      .image {
        width: 21em;
        margin: 0 auto;
      img {
          width: 100%;
          height: 100%;
          }
    }

    .img-login {
          /* svg on an img tag */
         
        }
    }

    .MuiTypography-colorError {
      color: #91918a !important;
    }

    .footer {
      margin-top: 2em;
        .btn:disabled {
          background-color: lightgray;
          cursor: initial;
        }
    }

    .MuiCircularProgress-root {
      color:#000 !important;
    }

    input:-webkit-autofill, input:focus:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 100px rgb(249, 249, 249) inset !important;
    }

    a {
      color: #91918a;
      text-decoration: none;
        &:hover {
        cursor:pointer;
        color: #000;
      }
    }
}





