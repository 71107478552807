.search-form-container {
	border: 2px solid black;
	border-radius: 5px;
	padding: 16px 32px 32px 32px;
	background-color: white;
	margin: 16px;
	color: black;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

	@media (min-width: 1025px) {
		max-width: 850px;
		margin: 32px auto;
	}

	h1 {
		font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
		text-align: center;
		font-size: xx-large
	}

	.MuiTypography-body1 {
 		color: black;
	}

	.MuiInputBase-root.Mui-focused  {
  		border-color: red !important;
	}

	.box-button-advanced  {
		color: black;
		background-color: white;
		border: 2px solid black;
		border-radius: 4px;
	 	height: 100%;
	 	transition: all 500ms ease-in-out;
	 	&:hover {
          background-color: #000 !important;
          color:#fff !important;
          border: 2px solid #000 !important;
        }
	}

	.box-button-advanced a {
		color: #000;
	}

	.box-button-advanced a:hover {
		color: #fff;
	}

	.rounded-button {
		color: black;
		background-color: white;
		border: 2px solid black;
		border-radius: 4px;
	 	height: 100%;
	 	transition: all 500ms ease-in-out;
	 	&:hover {
          background-color: #000 !important;
          color:#fff !important;
          border: 2px solid #000 !important;
        }
	}
	
	.rounded-input {
		color: black;
		background-color: white;
		border-radius: 10px;
	}

	.rounded-button2 {
		color: black;
		background-color: white;
		border: 2px solid black;
		border-radius: 10px;
	 	height: 100%;
	 	margin: 5px;
  		width: 20%;
  		transition: all 500ms ease-in-out;
	 	&:hover {
          background-color: #000 !important;
          color:#fff !important;
          border: 2px solid #000 !important;
        }
        @media (max-width: 640px) {
        	width: 45%;
        }

	}
}
