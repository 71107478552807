.DeletedProperties {
  color: white;
}


.result-table {
    border-collapse: collapse;
    margin: 10px auto;
    font-size: 0.9em;
    font-family: sans-serif;
    width: 95%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.result-table.loading {
  opacity: 0.2;
}

.result-table thead tr {
    background-color: #000;
    color: #ffffff;
    text-align: left;
}

.result-table th,
.result-table td {
    padding: 8px;
}

.result-table tbody tr {
    border-bottom: 1px solid #000;
}


.result-table tbody tr:nth-of-type(even) {
    background-color: #fff;
}

.result-table tbody tr:last-of-type {
    border-bottom: 2px solid #000;
}


.result-table tr:hover {
  background-color: #262626;
    color: white;
}

.result-table tr:nth-of-type(even):hover {
    background-color: #262626;
    color: white;
}


.result-table td:not(:last-child):hover {
  background-color: #3e3e3e;
  color: white;
}

.result-table td:last-child:hover>td{
    background-color: #FFF;
}

/* Pagination */

.MuiToolbar-root {
  color: #000;
  text-align: center;

} 


.MuiTableCell-root {
  display: table-cell;
  padding: 16px;
  font-size: 0.875rem;
  text-align: center !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 0px solid
    rgba(224, 224, 224, 1) !important;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}

.MuiTablePagination-spacer {
  display: none;
}

.MuiTablePagination-toolbar {
  justify-content: center !important;
}


MuiTablePagination-selectIcon {
  color: black !important;
}

/*Mobile */

.result-table {


@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {


  /* Force table to not be like tables anymore */
  table, thead, tbody, th, td, tr{ 
    display: block; 
  }
  
  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr { 
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr:nth-of-type(odd) { 
  background: #ccc;
  border-bottom: 1px solid #fff; 
}

th { 
  background: #333; 
  color: white; 
  font-weight: bold; 
}

td, th { 
  padding: 6px; 
  border: 1px solid #eee; 
  text-align: left; 
}

td:nth-child(1) {
  padding-left: 50%;
}

tr:nth-of-type(even):hover {
    background-color: #000;
    color: white;
}

tr:hover {

background-color: #000;
    color: white;
  }

  td { 
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #8a8a8a; 
    position: relative;
    padding-left: 50%; 
    height: 45px;
  }
  
  td:before { 
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%; 
    padding-right: 10px; 
    white-space: nowrap;
  }


  
  /*
  Label the data
  */
  td:nth-of-type(1):before { content: "Post Code"; }
  td:nth-of-type(2):before { content: "Tenure"; }
  td:nth-of-type(3):before { content: "Lease Term"; }
  td:nth-of-type(4):before { content: "District"; }
  td:nth-of-type(5):before { content: "County"; }
  td:nth-of-type(6):before { content: "Plot Size (acre)"; }
  td:nth-of-type(7):before { content: "Land"; }
  td:nth-of-type(8):before { content: "Property Type"; }
  td:nth-of-type(9):before { content: "Price Paid"; }
  td:nth-of-type(10):before { content: "Date Added"; }
  td:nth-of-type(11):before { content: ""; }
}

.MuiButton-contained {
      font-size: 12px;
      padding: 5px 20px;
      border: 2px solid #000 !important;
      border-radius: 3px !important;
      background-color: #fff !important;
      color: #000 !important;
      transition: all 250ms ease-in-out;
      cursor: pointer;
      margin-left: 1px;
        &:hover {
          background-color: #000 !important;
          color:#fff !important;
          transition: background-color 1s linear !important;
          border: 2px solid #fff !important;
        }
        &:focus {
          outline: none;
        }
    }

    .MuiTablePagination-caption {
    flex-shrink: 2;
}

}



