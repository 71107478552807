body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
  height: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1, h2, h5, p {
	color: #000;
}

h1 {
  
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    font-size: 2.5em;
}

h1 {
    text-transform: uppercase;    
}

a {
color: #000;
text-decoration: none;
}
a:hover {
	color: #91918a;
}

button {
  &.MuiButton-contained {
     background-color: #fff;
     color: #000;
     border: 1px solid #000;
     box-shadow: 0px 3px 1px -2px rgba(255, 255, 255, 0.2), 0px 2px 2px 0px rgba(255, 255, 255, 0.14), 0px 1px 5px 0px rgba(255, 255, 255, 0.12) !important;
   }

   &.MuiButton-contained:hover {
       background-color: #000;
       border: 1px solid #fff;
       color: #fff;
       box-shadow: 0px 3px 1px -2px rgba(255, 255, 255, 0.2), 0px 2px 2px 0px rgba(255, 255, 255, 0.14), 0px 1px 5px 0px rgba(255, 255, 255, 0.12) !important;
   }
}

.MuiContainer-root {
	
	margin-top: 5em;
}


.MuiSvgIcon-root {
    color: black !important;
}

.MuiSvgIcon-root:hover {
	color: lightgrey;
	text-decoration:none;
 }

 .MuiIcon-root {
    width: 1em;
    height: 1em;
    overflow: hidden;
    font-size: 1.5rem;
    flex-shrink: 0;
    user-select: none;
    color: black;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

/* New Sidebar Inverted Button */

.buttonsidebar {
     background-color: #000 !important;
     color: #fff !important;
     border: 1px solid #fff !important;
     transition: all 500ms ease-in-out !important;
     box-shadow: 0px 3px 1px -2px rgba(255, 255, 255, 0.2), 0px 2px 2px 0px rgba(255, 255, 255, 0.14), 0px 1px 5px 0px rgba(255, 255, 255, 0.12) !important;
   }

.buttonsidebar:hover {
    background-color: #fff !important;
    border: 1px solid #000 !important;
    color: #000 !important;
    box-shadow: 0px 3px 1px -2px rgba(255, 255, 255, 0.2), 0px 2px 2px 0px rgba(255, 255, 255, 0.14), 0px 1px 5px 0px rgba(255, 255, 255, 0.12) !important;
}

/* Radio Buttons & Checkbox */

.MuiRadio-colorSecondary.Mui-checked {
    color: #000 !important;
}

.MuiRadio-colorSecondary:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #000 !important;
}

.MuiCheckbox-colorSecondary:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

/* Google Calendar*/

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer !important;
  filter: invert(0) !important;
  opacity: 1 !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(0) !important;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 0.6 !important;
}

/* Progress Bars */

.MuiLinearProgress-colorPrimary {
    color: grey !important;
}

.MuiLinearProgress-colorSecondary {
    color: grey !important;
}


.MuiCircularProgress-colorPrimary {
    color: black !important;
}

.MuiCircularProgress-colorSecondary {
    color: black !important;
}

/* Border on Focused Element*/

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #000 !important;
  border-width: 2px;
}

/* Mobile Link Bar */

.makeStyles-appBar-5 {
  background-color: #181818 !important;
  }

.makeStyles-drawerHeader-10 {
    background-color: #181818 !important;
}

.makeStyles-drawerPaper-8 {
    background-color: #181818 !important;
}

/*.MuiTypography-body1 {
    color: white !important;
}*/

.MuiPaper-elevation1 {
    border: 1px solid black;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
}

input.leaflet-control-layers-selector {
  visibility: visible !important;
}

@import '~react-leaflet-markercluster/dist/styles.min.css'; 