.simple-dialog-container-calc {
	background-color: rgba (0.0.0) !important;
	
	.MuiPaper-root.MuiDialog-paper {
    	background-color: #000;
    	width: 600px;
    	border: 2px solid #fff;
    	border-radius: 10px;
    	color: #fff !important;
    	filter: saturate(50%);
	}

	.MuiTypography-colorTextSecondary {
    color: rgba(255, 255, 255, 0.54);
	}

	.MuiTypography-h6 {
    font-size: 1.25rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    color: #fff !important;
	}

	.MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    margin-bottom: 5px;
    padding: 16px 24px;
    border-bottom: 1px solid white;

	}

	.MuiButton-contained-modal {
    font-size: 14px;
    padding: 5px 20px;
    border: 2px solid #fff !important;
    border-radius: 3px !important;
    background-color: #000 !important;
    color: #fff !important;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    &:hover {
    	background-color: white !important;
    	border: 1px solid white !important;
        color: #000 !important;
    }
	}

	
	.MuiDialogActions-root {
    flex: 0 0 auto;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: flex-end;
    background-color: #000 !important;
    border-top: 1px solid white; 
	}

}