.TileContainer {
  text-align: center;
  margin-bottom: 20px;


  .Tile {
    display: inline-flex;
    background-color: black;
    width: 80px;
    height: 80px;
    padding: 10px;
    margin: 10px 20px;
    border-radius: 10px;
    border: 2px;
    border: 3px solid #FFFFFF;

    &.small {
      width: 40px;
      height: 40px;
      margin: 5px 10px;
    }

    &:hover {
      background-color: #91918A;
      cursor: pointer;
		}

    img {
      width: 100%;
      display: block;
    }
  }
  &.small .caption {
    // display:none;
    }
  .caption {
    // color:#000;
    font-size: small;
  }
}
