.search-result-item {
  margin: 16px;
  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 7px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  @media (min-width: 1025px) {
    max-width: 992px;
    margin: 32px auto;
  }

  table {
    width: 100%;

    tbody {
      color: black;
      tr td:nth-child(1),
      tr td:nth-child(3) {
        font-weight: bold;
        font-size: 1em;
        width: 75px;
        height: 30px;
        background-color: white;
        border-left: 1px solid #000;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
      }

      tr td:nth-child(2),
      tr td:nth-child(4) {
        background-color: #181818;
        color: white;
        padding: 10px;
        border-right: 1px solid #000;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
      }

      tr td:nth-child(1),
      tr td:nth-child(3) {
        padding: 10px;
      }
    }
  }
}

@keyframes TransitioningBackground {
  0% {
    background-position: 1% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 1% 0%;
  }
}

.resultbutton {
  width: 75px;
  height: 45px;
  border: 2px solid #000 !important;
  border-radius: 3px !important;
  background-color: #000 !important;
  color: #000 !important;
  cursor: pointer;
  transition: all 500ms ease-in-out;
  &:hover {
    background-color: #fff !important;
    border: 2px solid #000 !important;
    color: #fff !important;
    transition: all 500ms ease-in-out;
  }
  // for shine & gradient effects to work
  position: relative;
  overflow: hidden;
  // for background color shift
  background-image: (linear-gradient(270deg, #fff, #797979));
  background-size: 400% 400%;
  animation: TransitioningBackground 5s ease infinite;
  // to ease the button growth on hover
  transition: 0.8s;
  // psuedo-element shine animation left side
  &::before {
    content: "";
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    width: 60px;
    height: 100%;
    top: 0;
    filter: blur(30px);
    transform: translateX(-100px) skewX(-15deg);
  }

  // psuedo-element shine animation right side
  &::after {
    content: "";
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
    width: 30px;
    height: 100%;
    top: 0;
    filter: blur(5px);
    transform: translateX(-100px) skewX(-15deg);
  }

  // grow button and change background gradient on hover
  &:hover {
    background-image: (linear-gradient(to left, #181818, #797979));
    transform: scale(1.2);
    cursor: pointer;
  }
  // psuedo-elements for right- and left-side shine animations
  &::before,
  &::after {
    transform: translateX(300px) skewX(-15deg);
    transition: 0.7s;
  }
}

.resultbutton2 {
  width: 75px;
  height: 45px;
  border: 2px solid #000 !important;
  border-radius: 3px !important;
  background-color: #fff !important;
  color: #000 !important;
  cursor: pointer;
  transition: all 500ms ease-in-out;
  &:hover {
    background-color: #000 !important;
    border: 2px solid #000 !important;
    color: #fff !important;
    transition: all 500ms ease-in-out;
  }
}

.tenure-block {
  text-align: center;
  font-size: small;
  padding: 10px;
  border: 2px solid #000 !important;
  border-radius: 10px 0 10px 0;
  box-shadow: 5px 5px 10px grey;
}
.property-block {
  color: black;
  text-align: center;
  font-size: small;
  padding: 10px;
  border: 2px solid #000 !important;
  border-radius: 10px;
  box-shadow: 5px 5px 10px grey;
}
