.MuiPaper-root.MuiDialog-paper {
	background-color: #fff;
	border: 2px solid #000;
	border-radius: 10px;
	color: #000;

	h3 {
		color: black;
	}

	.MuiSelect-root {
 		color: #000!important;	
	}

	.MuiSvgIcon-root {
    color: black !important;
	}

	.MuiIconButton-colorSecondary:hover {
	background-color: rgba(0, 0, 0, 0.04) !important;
	}

	.MuiIconButton-colorSecondary {
	color: black !important;
	text-decoration:none !important;
 	}

	.close-button {
      font-size: 14px;
      padding: 5px 20px;
      border: 2px solid #000 !important;
      border-radius: 3px !important;
      background-color: #fff !important;
      color: #000 !important;
      transition: all 500ms ease-in-out;
      cursor: pointer;
        &:hover {
          background-color: #000 !important;
          color:#fff !important;
          transition: all 500ms ease-in-out !important;
          border: 2px solid #000 !important;
        }
        &:focus {
          outline: none;
        }
    }	
}






