.react-trello-card header span {
  width: 100%;
  white-space: break-spaces;

}

.mypropertiesheaderbar {
  background-color: #fff;
  overflow: hidden;
  border-bottom: 0.5px solid lightgrey;
  border-width:thin;
  text-align: center;
  padding: 20px;
   -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);

  .exportkanban {
    margin-left: 15px;
  }

  @media (max-width: 768px) {
  .exportkanban {
      margin-top: 10px;
  }
 }


  .MuiButton-contained {
    font-size: 14px;
    border: 2px solid #000 !important;
    border-radius: 3px !important;
    background-color: #fff !important;
    color: #000 !important;
    transition: all 250ms ease-in-out;
    cursor: pointer;
    &:hover {
      background-color: #000 !important;
      color: #fff !important;
      transition: background-color 1s linear !important;
    }
    &:focus {
      outline: none;
    }
  }
}


.boardContainer {
  article {
    cursor: auto;
    button {
      font-size: 12px;
      padding: 5px 20px;
      border: 2px solid #000 !important;
      border-radius: 3px !important;
      background-color: #fff !important;
      color: #000 !important;
      transition: all 500ms ease-in-out;
      cursor: pointer;
        &:hover {
          background-color: #000 !important;
          color:#fff !important;
          transition: all 500ms ease-in-out !important;
          border: 2px solid #000 !important;
        }
        &:focus {
          outline: none;
        }
    }
    > div > div:nth-child(1) {
      margin-top: 12px;
    }
  }
}
