
.MuiPaper-root.cookie-consent-dialog {
	background-color:rgb(255, 255, 255);
	border: 2px solid black;

	.MuiButton-textSecondary {
		color: black;
			&:hover {
			color: grey; 
			}
	}

	.MuiButton-contained-modal {
      font-size: 14px;
      padding: 5px 20px;
      border: 2px solid #000 !important;
      border-radius: 3px !important;
      background-color: #fff !important;
      color: #000 !important;
      cursor: pointer;
      transition: all 500ms ease-in-out;
        &:hover {
          background-color: #000 !important;
          color:#fff !important;
          transition: all 500ms ease-in-out;
        }
        &:focus {
          outline: none;
        }
    }


}

.MuiSnackbarContent-root {
    color: #fff;
    display: flex;
    padding: 6px 16px;
    flex-grow: 1;
    flex-wrap: wrap;
    font-size: 0.875rem;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    letter-spacing: 0.01071em;
    background-color: black !important;

    .MuiButton-contained-modal {
      font-size: 14px;
      padding: 5px 20px;
      border: 2px solid #fff !important;
      border-radius: 3px !important;
      background-color: #000 !important;
      color: #fff !important;
      cursor: pointer;
      transition: all 500ms ease-in-out;
        &:hover {
          background-color: #fff !important;
          border: 2px solid #fff !important;
          color:#000 !important;
          transition: all 500ms ease-in-out;
        }
        &:focus {
          outline: none;
        }
    }


}