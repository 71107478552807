.simple-dialog-container {
	

	.MuiDialogTitle-root {
		h2 {
			color: black;
		}
	}
	
	.MuiDialogContent-root {
		.MuiDialogContentText-root {
			color: black;
		}
	}

	.MuiButton-textPrimary {
    color: black;
    border: 2px solid black;
    border-radius: 4px;
    transition: all 500ms ease-in-out;
	}

    .MuiButton-textPrimary:hover{
    color: white;
    background-color: black;
    border: 2px solid black;
    border-radius: 4px;
	}

.MuiPaper-root.MuiDialog-paper{
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.2, 0, 0.2, 1) 0ms;
    background-color: #fff;
    border-radius: 4px;
	border: 2px solid #000;
	}

}