.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.google-map {
  width: 100%;
  height: 60vh;
  margin-bottom: 24px;
}

.gm-svpc {
  
}

.gm-style-mtc,
.gm-style-cc {
    display: none;
  }


        
