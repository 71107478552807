//Custom Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");


.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  background-color:#fff;
}


//Login Main Container
.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 99;

  .container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    box-shadow:
      0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
      0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
    border: 5px solid #000;
    border-radius: 10px;
    position: relative;
    z-index: 99;
    width: 100%;
    height: 100%;
    z-index: 99;
    padding: 16px 8px;
  }

  /*Using media to set the style If screen is bigger than 800px*/
    @media screen and (min-width: 800px) {
     .container {
      max-width: 600px;/*Limit max-width to 600px*/
      max-height:600px;
      margin: 0 auto;/*Centering this container*/
  }
}

  .footer {
    display: flex;
    justify-content: space-around;
  }
}





