.contactmain {
  background-color: white;
  border: 2px solid black;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  max-width: 865px; 

  h1, h2, h3, h4, h5, p {
    color: black;
  }

  .MuiSvgIcon-root {
    color: black;

  }

  a {
    color: #91918a;
    text-decoration: none;
      &:hover {
        cursor:pointer;
        color: #000;
    }
  }

.MuiButton-contained {
      font-size: 14px;
      padding: 5px 20px;
      border: 2px solid #000 !important;
      border-radius: 3px !important;
      background-color: #fff !important;
      color: #000 !important;
      transition: all 500ms ease-in-out;
      cursor: pointer;
        &:hover {
          background-color: #000 !important;
          color:#fff !important;
          transition: all 500ms ease-in-out !important;
          border: 2px solid #000 !important;
        }
        &:focus {
          outline: none;
        }
    }


}

  .contact-us-form .MuiGrid-root {
	 margin-bottom: 8px;

  }

  .contact_us {

 

	.MuiInputBase-root.Mui-disabled {
    color: #000;
    background-color: #fff;
    cursor: default;
  }

  .MuiFormLabel-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.54);
  }


  .MuiFilledInput-root {
      position: relative;
      transition: background-color 1000ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      background-color: #fff;
      border-radius: 4px; 
      border: 2px solid #000;
      box-shadow: 0.5px 0.5px 10px 0.3px #f3f3f3;
  }

  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border-color: rgba(255, 255, 255, 1);
  }

 //Form Text

  .MuiFormLabel-root {
    color: black;
    padding: 0;
  }

  .MuiFormLabel-root.Mui-disabled {
    color: black;
  }

  .MuiFormLabel-root.Mui-focused {
      color: #000;
  }

  .MuiFilledInput-underline:after {
      border-bottom: 2px solid #fff;
  }

  .MuiFilledInput-root:hover {
    background-color: #e7e7e5;
    color: #000 !important;
  }


}





